import {NgForOf} from "@angular/common";
import {scrollToElement} from "@app/shared/helpers";
import {Component, inject, OnInit, Renderer2} from '@angular/core';
import {AppStateService} from "@app/shared/services/app-state.service";
import {FooterComponent} from "@app/shared/ui-kits/footer/footer.component";
import {HeaderComponent} from "@app/shared/ui-kits/header/header.component";

@Component({
  selector: 'sf-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
  imports: [
    NgForOf,
    FooterComponent,
    HeaderComponent
  ]
})
export class TermsOfUseComponent implements OnInit {
  private readonly renderer2 = inject(Renderer2);
  private readonly appStateService = inject(AppStateService);

  protected selectedId = 1;
  protected contentTitles = [
    {id: 1, name: 'Interpretation and Definitions'},
    {id: 2, name: 'Acknowledgment '},
    {id: 3, name: 'Acceptable Use'},
    {id: 4, name: 'Security'},
    {id: 5, name: 'Links to Other Websites'},
    {id: 6, name: 'User Accounts'},
    {id: 7, name: 'Copyright Policy'},
    {id: 8, name: 'Billing and Payment'},
    {id: 9, name: 'Trials & Discounts'},
    {id: 10, name: 'Termination '},
    {id: 11, name: 'Limited Warranties and Liability'},
    {id: 12, name: 'Governing Law'},
    {id: 13, name: 'Changes'},
  ];

  protected contentsInfo: { id: number, title: string, info: string }[] = [
    {
      id: 1,
      title: 'Interpretation and Definitions',
      info: '<div class="sf-policy-text-justify">' +
        '<p class="sf-policy"><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>' +
        '<p class="sf-policy"><strong>Company</strong> (referred to as either "the Company", “Selfcv”, ' +
        '"We", "Us" or "Our" in this Agreement) refers to <strong>SELFCV LLC</strong>' +
        ', a company organised and existing under the laws of the Republic of Armenia, with its registered ' +
        'address at: Nikoghayos Tigranyan blind alley 1,  0014, Yerevan, Armenia.</p>' +
        '<p class="sf-policy"><strong>Country</strong> refers to Armenia.' +
        '</p>' +
        '<p class="sf-policy"><strong>Service</strong> refers to the Website and the services thereof.</p>' +
        '<p class="sf-policy"><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>' +
        '<p class="sf-policy"><strong>Website</strong> refers to the official website of Selfcv, accessible from https://www.selfcv.com.</p>' +
        '<p class="sf-policy"><strong>YOU</strong> means the individual accessing or using the Service, or the company, ' +
        'or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p></div>'
    },
    {
      id: 2,
      title: 'Acknowledgment',
      info: '<div class="sf-policy-text-justify">These are the Terms and Conditions governing the use of this Service and the agreement that operates ' +
        'between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.\n' +
        'Your access to and use of the Service is conditioned on Your acceptance of and compliance with these ' +
        'Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.\n' +
        'By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of ' +
        'these Terms and Conditions then You may not access the Service.</div>\n'
    },
    {
      id: 3, title: 'Acceptable Use',
      info: '<div class="sf-policy-text-justify">You must not use Selfcv to copy, store, host, transmit, send, use, publish or distribute any material which consists of' +
        ' (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.\n' +
        'You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining,' +
        ' data extraction and data harvesting) on or concerning Selfcv without an authorized person\'s express written consent from Selfcv.\n' +
        'You represent that You are over the age of 18. The Company does not permit those under 18 to use the Service. If You are under the ' +
        'age required to manage your own Selfcv Account, You must have your parent or legal guardian’s permission to use a Selfcv Account. ' +
        'Please have your parent or legal guardian read these terms with You. If you’re a parent or legal guardian, and You allow your child ' +
        'to use the services, then these terms apply to You and You are responsible for your child’s activity on the services. We don’t take ' +
        'the responsibility for the actions, projects and payments made on the platform by children under 18.</div>\n'
    },
    {
      id: 4, title: 'Security',
      info: '<div class="sf-policy-text-justify">Selfcv has implemented commercially reasonable technical and organizational measures designed to ' +
        'secure your private information from accidental loss and unauthorized access, use, alteration or disclosure.' +
        ' However, We cannot guarantee that unauthorized third parties will never be able to defeat those measures or ' +
        'use your private information for improper purposes.</div>'
    },
    {
      id: 5, title: 'Links to Other Websites',
      info: '<div class="sf-policy-text-justify">Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.\n' +
        'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any ' +
        'third-party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, ' +
        'directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance ' +
        'on any such content, goods or services available on or through any such web sites or services.\n' +
        'We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</div>\n'
    },
    {
      id: 6, title: 'User Accounts',
      info: '<div class="sf-policy-text-justify">When You create an Account with Us, You must provide Us information that is accurate, complete, and current at all times.\n' +
        'When You create an Account on Selfcv, You are fully responsible for maintaining its security and any activities that occur ' +
        'under your Account. Please, use a "strong" password (or authenticate via a third-party) to ensure that your private information, ' +
        'your resumes and billing information are safe.\n' +
        'You are responsible for safeguarding the password that You use to access the Service and for any activities or ' +
        'actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.\n' +
        'You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of ' +
        'any breach of security or unauthorized use of Your Account.\n' +
        'All information of users login, emails, and passwords are very confidential. All the passwords provided by our users ' +
        'are one way encrypted, and even We cannot see it.</div>\n'
    },
    {
      id: 7, title: 'Copyright Policy',
      info: '<div class="sf-policy-text-justify">All information, content, operations, features, and software shown on, sent through, or utilized with Selfcv including directories, ' +
        'guides, articles, reviews, text, photos, images, illustrations, videos, source and object code, software, data, and all other related ' +
        'materials, as well as the selection, organization, and overall design of Selfcv (collectively referred to as the "Content") are protected ' +
        'by relevant copyrights and other proprietary (including intellectual property) rights and are owned by Selfcv.\n' +
        'We respect the intellectual property of others. If You believe that your work has been copied in a way that constitutes copyright infringement' +
        ' or if You are aware of any infringing material placed on our Website, please contact us.</div>\n'
    },
    {
      id: 8, title: 'Billing and Payment',
      info: '<div class="sf-policy-text-justify">After purchasing a premium Account your receipt will be sent to the email address provided when You set up your Account,' +
        ' within 48 hours of making the purchase. If You have not received your receipt within 48 hours of booking, please check your spam ' +
        'or junk email folder or filter and then contact us. It is your responsibility to ensure that your email is set up to allow You to receive ' +
        'your bill, and We cannot accept any liability for any consequences of your not doing so. However, sometimes technical problems may occur ' +
        'from our side, and in this case, You can request your receipt by sending us an email at <a class="sf-link" href= "mailto: support@selfcv.com"> support@selfcv.com</a>.\n' +
        'In the unfortunate event of the software, apps, applications or servers crashing or having technical problems during your purchase which ' +
        'will result in your premium Account not being activated, please email us at <a class="sf-link" href= "mailto: support@selfcv.com"> support@selfcv.com</a>, and the problem will be investigated. ' +
        'This will result in different compensations or refunds in the case We find the problem was caused by our part.\n' +
        'When You choose to extend the Premium Account by any period, this will be extended according to the calculations made by our databases ' +
        'and the number of days per month can range from 28 to 31, depending on the particular month.\n' +
        'We reserve the right to delete also the Premium Accounts if the user is misusing Selfcv or doing malicious activities, but We doubt ' +
        'this will be the case.\n' +
        'Your private details are encrypted between the client (YOU, as a user) and the server using an SSL certificate, to protect your data.\n' +
        'We reserve the right to change the price of our services when introducing new promotions, special packages or when changes are being made ' +
        'regarding taxes, VAT regulations or external factors such as inflation/deflation.</div>\n'
    },
    {
      id: 9, title: 'Trials & Discounts',
      info: '<div class="sf-policy-text-justify">Any trial or reduced pricing (promo code) must be used within the specified time. We reserve the right to limit You to one trial period ' +
        'or a single promo code as well as to prohibit the combination of trials, promo codes, and other offers (such as inviting friends).' +
        ' Credit accumulated through inviting friends cannot be exchanged, refunded, replaced or redeemed for cash.</div>'
    },
    {
      id: 10, title: 'Termination ',
      info: '<div class="sf-policy-text-justify">We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation ' +
        'if You breach these Terms and Conditions and you will remain liable for all amounts due up to and including the date of termination.\n' +
        'Upon termination, Your right to use the Service will cease immediately. If You wish to terminate your Account, You may do so through the Account page.</div>\n'
    },
    {
      id: 11, title: 'Limited Warranties and Liability',
      info: '<div class="sf-policy-text-justify">Selfcv does not make any warranty about the reliability of the Services and does not guarantee the security of user data despite best efforts. ' +
        'The Service is provided “AS IS” and You agree to not hold us responsible nor to seek indemnification for any damages that may arise as a result of ' +
        'the loss of use, data, or profits connected to the performance of the Services or failure in such performance.</div>'
    },
    {
      id: 12,
      title: 'Governing Law',
      info: '<div class="sf-policy-text-justify">These terms shall be governed and construed in accordance with the laws of Armenia, without regard to its conflict of law provisions. ' +
        'Your use of the Application may also be subject to other local, state, national, or international laws.</div>'
    },
    {
      id: 13,
      title: 'Changes',
      info: '<div class="sf-policy-text-justify">We reserve the right to modify these terms at any time. If a revision is material, We will try to provide at least 15 days notice prior to ' +
        'any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our' +
        ' Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, please stop' +
        ' using the Service.</div>'
    },
  ];

  ngOnInit() {
    this.appStateService.setPageSeo('terms_of_use', this.renderer2);
  }

  protected scrollToElementById(id: number): void {
    this.selectedId = id;
    const title = this.contentsInfo.find((info) => info.id === id)?.title;
    const element = document.getElementById(title || '');
    scrollToElement(element!, 120);
  }

}
