import { Component } from '@angular/core';

@Component({
  selector: 'sf-blog-list',
  imports: [],
  templateUrl: './blog-list.component.html',
  styleUrl: './blog-list.component.scss'
})
export class BlogListComponent {

}
