import {finalize} from "rxjs";
import {environment} from "@env";
import {parseJwt} from "@app/shared/helpers";
import {LngRoutePipe} from "@app/shared/pipes";
import {SfTrimDirective} from "@app/shared/directives";
import {NzMessageService} from "ng-zorro-antd/message";
import {HttpErrorResponse} from "@angular/common/http";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {JWT, MySocialUser, User} from "@app/shared/interfaces";
import {NgIf, NgOptimizedImage, NgStyle} from "@angular/common";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {AnalyticsService, AuthService} from "@app/shared/services";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {AppStateService} from "@app/shared/services/app-state.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {afterNextRender, Component, DestroyRef, inject, OnInit, Renderer2} from '@angular/core';

declare const google: any;

@Component({
  selector: 'sf-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  imports: [
    NgIf,
    NgStyle,
    RouterLink,
    LngRoutePipe,
    SfTrimDirective,
    TranslateModule,
    NgOptimizedImage,
    ReactiveFormsModule
  ]
})
export class SignInComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly renderer2 = inject(Renderer2);
  private readonly destroyRef = inject(DestroyRef);
  private readonly formBuilder = inject(FormBuilder);
  private readonly authService = inject(AuthService);
  private readonly activatedRoute = inject(ActivatedRoute);
  protected readonly appStateService = inject(AppStateService);
  private readonly translateService = inject(TranslateService);
  private readonly analyticsService = inject(AnalyticsService);
  private readonly nzMessageService = inject(NzMessageService);

  protected readonly signInForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]],
    password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(128)]]
  });
  public loaderState = false;
  public errorMessage = '';

  constructor() {
    afterNextRender(() => {
      setTimeout(() => {
        this.initializeGoogleSignIn();
      });
    });
  }

  public ngOnInit() {
    this.appStateService.setPageSeo('sign_in', this.renderer2);
    this.analyticsService.track('SIGN IN page visited');
  }

  public onSubmit() {
    if (this.signInForm.valid) {
      this.loaderState = true;
      this.signIn();
    }
  }

  private initializeGoogleSignIn() {
    google.accounts.id.initialize({
      client_id: environment.google_oauth_client_id,
      callback: this.googleSocialSignIn.bind(this)
    });

    google.accounts.id.renderButton(
      document.getElementById("sign-in-google"),
      {theme: "outline", size: "large", text: "signin_with"}
    );
  }

  private googleSocialSignIn(data: { credential: string; select_by: string; }) {
    const user: MySocialUser = {
      credential: data.credential,
      provider: 'google'
    };

    this.loaderState = true;

    this.authService.socialSignIn(user)
      .pipe(
        finalize(() => this.loaderState = false),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (res: JWT) => {
          // Adding userId so we can track
          const jwtContent = parseJwt(res.accessToken);
          user.id = jwtContent.userId;
          this.getMe();
        },
        error: () => {
          this.nzMessageService.error(this.translateService.instant('project_messages.social_login_failed'));
        }
      });
  }

  private signIn() {
    this.authService.login(this.signInForm.value)
      .pipe(
        finalize(() => this.loaderState = false),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: () => {
          this.getMe();
        },
        error: (err: HttpErrorResponse) => {
          this.errorMessage = err.error.reason;
        }
      });
  }

  private getMe() {
    this.authService.getMe()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: User) => {
        const url = this.returnUrl.replace(/\/+$/, ''); // remove last '/' '/dashboard/' -> '/dashboard'
        this.router.navigateByUrl(url);
        this.appStateService.user = res;
        this.appStateService.user$.next(res);
      });
  }

  private get returnUrl(): string {
    return this.activatedRoute.snapshot.queryParams['returnUrl'] || this.appStateService.getLngRout('home');
  }

}

