import {environment} from "@env";
import {LngKeys} from "@app/shared/enums";
import {TranslateService} from "@ngx-translate/core";
import {NzMessageService} from "ng-zorro-antd/message";
import {filter, finalize, first} from "rxjs/operators";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {DOCUMENT, ViewportScroller} from "@angular/common";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NzModalModule, NzModalService} from "ng-zorro-antd/modal";
import {AppStateService} from "@app/shared/services/app-state.service";
import {AnalyticsService} from "@app/shared/services/analytics.service";
import {HelperComponent} from "@app/shared/ui-kits/helper/helper.component";
import {parseJwt, parseQueryParams, removeQueryParams} from "@app/shared/helpers";
import {AuthService, PaymentService, TemplateService} from "@app/shared/services";
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from "@angular/router";
import {afterNextRender, Component, DestroyRef, inject, OnInit, Renderer2} from '@angular/core';
import {
  AmeriaBankQueryParams,
  AmeriaPaymentConfirmRes,
  JWT,
  MySocialUser,
  SEO_Configs,
  Template,
  User
} from "@app/shared/interfaces";

// declare const Paddle: any;
declare let gtag: Function;
declare const google: any;

@Component({
  selector: 'sf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    RouterOutlet,
    NzModalModule,
    NzDropDownModule,
    HelperComponent,
  ]
})
export class AppComponent implements OnInit {
  protected readonly router = inject(Router);
  private readonly document = inject(DOCUMENT);
  private readonly renderer = inject(Renderer2);
  protected readonly destroyRef = inject(DestroyRef);
  private readonly authService = inject(AuthService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly paymentService = inject(PaymentService);
  // private readonly paymentService = inject(PaymentService);
  private readonly nzModalService = inject(NzModalService);
  private readonly appStateService = inject(AppStateService);
  private readonly templateService = inject(TemplateService);
  private readonly translateService = inject(TranslateService);
  private readonly viewportScroller = inject(ViewportScroller);
  private readonly analyticsService = inject(AnalyticsService);
  private readonly nzMessageService = inject(NzMessageService);

  constructor() {
    afterNextRender(() => {
      this.appStateService.init_auth_token();
      this.getMe();
      this.listenToInternetConnection();
      this.getUserLocation();
      setTimeout(() => this.initializeGoogleSignIn(), 5000);
      setTimeout(() => this.confirmAmeriaBankPayment());
      this.appStateService.getAllConstants();
    });
  }

  ngOnInit() {
    // this.translateService.setDefaultLang('en');
    // this.translateService.use(this.appStateService.selectedLanguage.key);

    // this.getMe();

    // this.getAPIAddress();
    // this.getPaymentPrices();
    // this.getTemplatesToSelect();

    this.listenToUserLogin();
    this.listenToRouterEvents();


    this.setHreflangTags();
    this.getLngFromRoute();

    this.translateService.onLangChange
      .subscribe((res) => {
        this.appStateService.dateTime = new Date().getTime();

        Object.values(LngKeys).forEach((key) => {
          this.renderer.removeClass(this.document.body, `lang_${key}`);
        });

        this.renderer.addClass(this.document.body, `lang_${res.lang}`);

        this.appStateService.setHtmlLangAttribute(res.lang, this.renderer);
        this.appStateService.addMetaTags(this.appStateService.SEO_Configs[res.lang as keyof SEO_Configs], this.renderer);

        setTimeout(() => this.initializeGoogleSignIn(), 5000);
      });

    this.appStateService.logOut$
      .subscribe(() => {
        setTimeout(() => this.initializeGoogleSignIn(), 5000);
      });
  }

  private getLngFromRoute() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        first()
      )
      .subscribe(() => {
        const lng = this.currentLngKey;
        this.appStateService.setLng(lng);
      });
  }

  private get currentLngKey() {
    const paths = this.router.url.split('/').filter((path) => path.length);

    let lng: LngKeys = paths.at(0) as LngKeys || LngKeys.EN;
    if (!Object.values(LngKeys).includes(lng as LngKeys)) {
      lng = LngKeys.EN;
    }

    return lng;
  }

  private setHreflangTags() {

    const LANGUAGES = [
      {lang: 'en', url: 'https://www.selfcv.com'},
      {lang: 'ru', url: 'https://www.selfcv.com/ru/'},
      {lang: 'fr', url: 'https://www.selfcv.com/fr/'},
      {lang: 'de', url: 'https://www.selfcv.com/de/'},
      {lang: 'es', url: 'https://www.selfcv.com/es/'},
    ];

    // Remove existing hreflang tags to avoid duplicates
    const existingTags = this.document.querySelectorAll("link[rel='alternate'][hreflang]");
    existingTags.forEach(tag => tag.remove());

    // Loop through defined languages and add hreflang tags
    LANGUAGES.forEach(language => {
      const link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'alternate');
      this.renderer.setAttribute(link, 'hreflang', language.lang);
      this.renderer.setAttribute(link, 'href', language.url);
      this.renderer.appendChild(this.document.head, link);
    });

    // Add x-default tag
    const xDefaultLink = this.renderer.createElement('link');
    this.renderer.setAttribute(xDefaultLink, 'rel', 'alternate');
    this.renderer.setAttribute(xDefaultLink, 'hreflang', 'x-default');
    this.renderer.setAttribute(xDefaultLink, 'href', 'https://www.selfcv.com');
    this.renderer.appendChild(this.document.head, xDefaultLink);
  }

  private getUserLocation() {
    this.paymentService.getUserLocation()
      .pipe(
        finalize(() => this.appStateService.isUserPlansReady = true),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res) => {
        console.log('user location =>', res);
        this.appStateService.userLocation = res;
        this.appStateService.userLocationInit$.next(res);
        this.analyticsService.track('User location', res);
      });
  }

  private confirmAmeriaBankPayment() {
    if (this.appStateService.isAuthenticated) {
      const queryParams: AmeriaBankQueryParams = parseQueryParams(window.location.search || '');

      if (queryParams) {

        const condition = queryParams.hasOwnProperty('paymentID') &&
          ['resposneCode', 'responseCode'].some((item) => queryParams.hasOwnProperty(item));

        if (condition) {
          this.paymentService.confirmAmeriaPayment(queryParams.paymentID)
            .pipe(
              finalize(() => removeQueryParams(this.router)),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((res: AmeriaPaymentConfirmRes) => {
              this.nzMessageService.create(res.status.toLowerCase(), res.description);
              this.appStateService.user!.planIsValid = res.planIsValid;
              this.appStateService.user!.activeUntil = res.activeUntil;
              this.analyticsService.track("Payment Confirmed!!!", res);
              this.getMe();
            });
        }
      }

    }
  }

  private listenToInternetConnection() {
    window.addEventListener('online', () => {
      this.nzMessageService.success(
        this.translateService.instant('project_messages.you_online')
      );
    });

    window.addEventListener('offline', () => {
      this.nzMessageService.warning(
        this.translateService.instant('project_messages.you_offline')
      );
    });
  }

  private listenToUserLogin() {
    this.appStateService.user$
      .pipe(
        filter((user) => Boolean(user))
      )
      .subscribe(() => {
        this.getUserTemplates();
      });
  }

  private listenToRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        if (this.appStateService.isBrowser) {
          this.scrollToTop();

          if (environment.env === 'production') {
            this.trackEvent(event as NavigationEnd);
          }
        }
        this.nzModalService.closeAll();
      });
  }

  private scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.document.body.scrollTop = 0;

    setTimeout(() => {
      window.scrollTo(0, 0);
      this.document.body.scrollTo(0, 0);
    }, 100);

    window.scrollTo(0, 0);
    this.document.body.scrollTo(0, 0);
  }

  private trackEvent(event: NavigationEnd) {
    gtag('set', 'page_path', event.urlAfterRedirects);
    gtag('event', 'page_view');
  }

  private getMe() {
    if (this.appStateService.authToken || this.appStateService.onPrintPage()) {
      this.authService.getMe()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res: User) => {
          this.appStateService.user = res;
          this.appStateService.user$.next(res);
          this.analyticsService.identifyMixpanelUser(res);
          this.getUserTemplates();

          console.log('user', res);
        });
    }
  }

  private getUserTemplates() {
    this.templateService.getTemplates()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: Template[]) => {
        console.log('user Templates', res);
        this.appStateService.userTemplates = res;
        this.appStateService.userTemplates$.next(res);
      });
  }

  private initializeGoogleSignIn() {
    if (this.appStateService.isBrowser) {
      if (!this.appStateService.isAuthenticated) {
        const condition = ![
          this.appStateService.getLngRout('sign_in'),
          this.appStateService.getLngRout('sign_up')
        ]
          .some((route) => this.router.url.includes(route))

        if (condition) {
          google.accounts.id.initialize({
            client_id: environment.google_oauth_client_id,
            callback: this.googleSocialSignIn.bind(this)
          });

          google.accounts.id.prompt();
        }
      }
    }
  }

  private googleSocialSignIn(data: { credential: string; select_by: string; }) {
    const user: MySocialUser = {
      credential: data.credential,
      provider: 'google'
    };

    this.authService.socialSignIn(user)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res: JWT) => {
          // Adding userId so we can track
          const jwtContent = parseJwt(res.accessToken);
          user.id = jwtContent.userId;
          this.getMe();
          this.router.navigateByUrl(this.returnUrl);
        },
        error: () => {
          this.nzMessageService.error(
            this.translateService.instant('project_messages.social_login_failed')
          );
        }
      });
  }

  /*private getTemplatesToSelect() {
    this.templateService.getTemplatesToSelect()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: Template[]) => {
        // this.appStateService.templates = res;
        console.log(res);
      });
  }*/

  private get returnUrl(): string {
    return this.activatedRoute.snapshot.queryParams['returnUrl'] || this.appStateService.getLngRout('home');
  }

  /*private getAPIAddress() {
    this.paymentService.getAPIAddress()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        console.log('APIAddress', res);

        /!*interval(500)
          .pipe(
            takeWhile(() => !Paddle, true),
            filter(() => Boolean(Paddle))
          )
          .subscribe(() => {
            this.setUpJSPaddle(res);
          });*!/

      });
  }*/

  /*private getPaymentPrices() {
    this.paymentService.getPaymentPrices()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        console.log('billing prices', res);
        this.appStateService.billingPlans = res.response.products;
      });
  }*/

  /*private setUpJSPaddle(res: string) {
    Paddle.Environment.set("sandbox");

    Paddle.Initialize({
      token: "test_382d3434e3c21706bd450a58a0b",
      eventCallback: (data: any) => {
        console.log("------------data-------------", data);

        if (data.name === 'checkout.completed') {
          this.appStateService.checkoutComplete$.next();
          setTimeout(() => this.getMe(), 3000);
        }


        if (data.name === PaddleEventTypes.LOADED) {
          Paddle.Spinner.hide();
          // set here some variable showCheckoutInfo: true
          // set user email and pass to that form
        }

        if (data.name === PaddleEventTypes.CUSTOMER_CREATED) {
          // Update price based on the price coming with event (total, tax ... see consoled)
          console.log('PaddleEventTypes.CUSTOMER_CREATED', data);
          console.log(data.data);
          console.log(data.data.currency_code);
          console.log(data.data.totals);
        }

        if (data.name === PaddleEventTypes.CUSTOMER_UPDATED) {
          // when user changes the country - this will update the price accordingly
          // because we have some country with different tax and also our custom country prices

          console.log('PaddleEventTypes.CUSTOMER_UPDATED', data);
          console.log(data.data);
          console.log(data.data.currency_code);
          console.log(data.data.totals);
        }

        if (data.name === PaddleEventTypes.CHECKOUT_COUPON_REMOVE) {
          // this must be handled, related coupon
        }

        if (data.name === PaddleEventTypes.PAYMENT_COMPLETE) {
          // Checkout payment completed event
          console.log('PaddleEventTypes.PAYMENT_COMPLETE');
        }

        if (data.name === PaddleEventTypes.COMPLETE) {
          // here we can close checkout form and navigate user to dashboard
          console.log('PaddleEventTypes.COMPLETE', data.eventData); // Data specifics on the event
          this.appStateService.checkoutComplete$.next();
          this.getMe();
        } else if (data.name === PaddleEventTypes.CLOSE) {
          console.log(data.eventData); // Data specifics on the event
        } else if (data.name === PaddleEventTypes.ERROR) {
          // generic errors
          console.log(data.eventData); // Data specifics on the event
        }
      }
    });

    const request: PricePreviewBody = {
      items: pricePreviewBodyItems,
      customerIpAddress: res
    };

    Paddle.PricePreview(request)
      .then((pricePreview: PricePreview) => {
        this.appStateService.pricePreview = pricePreview;
        console.log('pricePreview', pricePreview);
      })
      .catch((error: any) => {
        /!* TODO Armen cover this *!/
        // If not supported country will be passed, there could be errors.
        // It is important to pass supported country only in the paddle payment list.
        // If there is an error, we can pass some default country - for example Germany code (DE).
        console.error('Paddle.PricePreview error', error);
      });
  }*/

}
