import {RouterLink} from "@angular/router";
import {LngRoutePipe} from "@app/shared/pipes";
import {NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {Component, inject, OnInit, Renderer2} from '@angular/core';
import {AppStateService} from "@app/shared/services/app-state.service";
import {FooterComponent} from "@app/shared/ui-kits/footer/footer.component";
import {HeaderComponent} from "@app/shared/ui-kits/header/header.component";

@Component({
  selector: 'sf-about-us',
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
  imports: [
    FooterComponent,
    HeaderComponent,
    NgOptimizedImage,
    RouterLink,
    TranslateModule,
    LngRoutePipe,
  ]
})
export class AboutUsComponent implements OnInit {
  private readonly renderer2 = inject(Renderer2);
  protected readonly appStateService = inject(AppStateService);

  ngOnInit() {
    this.appStateService.setPageSeo('about_us', this.renderer2);
  }

}
