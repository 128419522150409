import {finalize} from "rxjs/operators";
import {TranslateModule} from "@ngx-translate/core";
import {ContactUsService} from "@app/shared/services";
import {NzMessageService} from "ng-zorro-antd/message";
import {SfTrimDirective} from "@app/shared/directives";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {AppStateService} from "@app/shared/services/app-state.service";
import {FooterComponent} from "@app/shared/ui-kits/footer/footer.component";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {HeaderComponent} from "@app/shared/ui-kits/header/header.component";
import {Component, DestroyRef, inject, OnInit, Renderer2} from '@angular/core';
import {ProjectSocialIconsComponent} from "@app/shared/ui-kits/project-social-icons/project-social-icons.component";

@Component({
  selector: 'sf-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
  imports: [
    HeaderComponent,
    FooterComponent,
    SfTrimDirective,
    TranslateModule,
    ReactiveFormsModule,
    ProjectSocialIconsComponent
  ]
})
export class ContactUsComponent implements OnInit {
  private readonly renderer2 = inject(Renderer2);
  private readonly destroyRef = inject(DestroyRef);
  private readonly formBuilder = inject(FormBuilder);
  private readonly appStateService = inject(AppStateService);
  private readonly contactUsService = inject(ContactUsService);
  private readonly nzMessageService = inject(NzMessageService);

  public readonly contactUsForm = this.formBuilder.group({
    fullName: [null, [Validators.required, Validators.maxLength(128)]],
    email: [
      this.appStateService.user?.email || null,
      [Validators.required, Validators.email, Validators.maxLength(128)]
    ],
    subject: [null, [Validators.maxLength(128)]],
    message: [null, [Validators.required, Validators.maxLength(128)]],
  });

  protected btnLoader = false;

  ngOnInit() {
    this.appStateService.setPageSeo('contact_us', this.renderer2);

    this.appStateService.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        this.contactUsForm.get('email')?.setValue(user?.email || null);
      });
  }

  protected onSubmit() {
    if (this.contactUsForm.valid) {
      this.btnLoader = true;
      this.contactUsService.contactUs(this.contactUsForm.value)
        .pipe(
          finalize(() => this.btnLoader = false),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(() => {
          this.nzMessageService.success("Success! We’ve received your message and will contact you soon.");
        });
    }
  }

}
