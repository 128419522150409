import {Routes} from '@angular/router';
import {authGuard, dashboardGuard} from "@app/shared/guards";
import {AboutUsComponent} from '@app/pages/about-us/about-us.component';
import {ContactUsComponent} from '@app/pages/contact-us/contact-us.component';
import {SignInComponent} from '@app/layouts/auth/pages/sign-in/sign-in.component';
import {SignUpComponent} from '@app/layouts/auth/pages/sign-up/sign-up.component';
import {TermsOfUseComponent} from '@app/pages/terms-of-use/terms-of-use.component';
import {TmPrintPageComponent} from '@app/pages/tm-print-page/tm-print-page.component';
import {RefundPolicyComponent} from '@app/pages/refund-policy/refund-policy.component';
import {BlogListComponent} from "@app/layouts/career-blog/blog-list/blog-list.component";
import {ShowBlogComponent} from "@app/layouts/career-blog/show-blog/show-blog.component";
import {PageNotFoundComponent} from "@app/pages/page-not-found/page-not-found.component";
import {PrivacyPolicyComponent} from '@app/pages/privacy-policy/privacy-policy.component';
import {UserPageComponent} from "@app/layouts/account/pages/user-page/user-page.component";
import {CreateBlogComponent} from "@app/layouts/career-blog/create-blog/create-blog.component";
import {HomePageComponent} from "@app/layouts/main-layout/pages/home-page/home-page.component";
import {ChoosePlanComponent} from "@app/layouts/account/pages/choose-plan/choose-plan.component";
import {BillingPageComponent} from '@app/layouts/account/pages/billing-page/billing-page.component';
import {ResetPasswordComponent} from '@app/layouts/auth/pages/reset-password/reset-password.component';
import {PricingPageComponent} from "@app/layouts/main-layout/pages/pricing-page/pricing-page.component";
import {ForgotPasswordComponent} from '@app/layouts/auth/pages/forgot-password/forgot-password.component';
import {ConfirmEmailComponent} from "@app/layouts/main-layout/pages/confirm-email/confirm-email.component";
import {CvTemplatesPageComponent} from '@app/layouts/main-layout/pages/cv-templates-page/cv-templates-page.component';
import {
  ChangePasswordPageComponent
} from '@app/layouts/account/pages/change-password-page/change-password-page.component';
import {
  CoverLettersPageComponent
} from '@app/layouts/main-layout/pages/cover-letters-page/cover-letters-page.component';

const en_routes: Routes = [
  {
    path: '',
    loadComponent: () => import('@app/layouts/main-layout/main-layout.component'),
    children: [
      {path: '', component: HomePageComponent},
      {path: 'cv-templates', component: CvTemplatesPageComponent},
      {path: 'cover-letters', component: CoverLettersPageComponent},
      {path: 'pricing', component: PricingPageComponent},
      {
        path: 'resume-examples',
        loadComponent: () => import('@app/layouts/resume-examples/resume-examples.component'),
      },
    ],
  },
  {
    path: 'auth',
    loadComponent: () => import('@app/layouts/auth/auth.component'),
    canActivate: [authGuard],
    children: [
      {path: '', redirectTo: 'sign-in', pathMatch: 'full'},
      {path: 'sign-in', component: SignInComponent},
      {path: 'sign-up', component: SignUpComponent},
      {path: 'forgot-password', component: ForgotPasswordComponent},
      {path: 'reset-password', component: ResetPasswordComponent},
    ]
  },
  {
    path: 'account',
    loadComponent: () => import('@app/layouts/account/account.component'),
    canActivate: [dashboardGuard],
    children: [
      {path: '', redirectTo: 'user', pathMatch: 'full'},
      {path: 'user', component: UserPageComponent},
      {path: 'security', component: ChangePasswordPageComponent},
      {path: 'billing-history', component: BillingPageComponent},
      {path: 'choose-plan', component: ChoosePlanComponent},
    ]
  },
  {
    path: 'dashboard',
    loadComponent: () => import('@app/layouts/dashboard/dashboard.component'),
    canActivate: [dashboardGuard],
  },
  {
    path: 'documents/:documentId',
    loadComponent: () => import('@app/layouts/builder/builder.component'),
    canActivate: [dashboardGuard]
  },
  {path: 'terms-of-use', component: TermsOfUseComponent},
  {path: 'refund-policy', component: RefundPolicyComponent},
  {path: 'confirm-email', component: ConfirmEmailComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'contact-us', component: ContactUsComponent},
  {path: 'about-us', component: AboutUsComponent},
  {
    path: 'blog',
    loadComponent: () => import('@app/layouts/career-blog/career-blog.component'),
    children: [
      {path: '', component: BlogListComponent},
      {path: 'create', component: CreateBlogComponent},
      {path: ':profession', component: ShowBlogComponent},
    ]
  },
  {
    path: 'template-print/3cc7a492-8c98-4133-8b3d-a1bef3f1c9ce/:documentId',
    component: TmPrintPageComponent,
  },
];

const ru_routes: Routes = [
  {
    path: 'ru',
    loadComponent: () => import('@app/layouts/main-layout/main-layout.component'),
    children: [
      {path: '', component: HomePageComponent},
      {path: 'shablony-rezyume', component: CvTemplatesPageComponent},
      {path: 'soprovoditelnie-pisma', component: CoverLettersPageComponent},
      {path: 'tseny', component: PricingPageComponent},
    ],
  },
  {
    path: 'ru/avtorizatsiya',
    loadComponent: () => import('@app/layouts/auth/auth.component'),
    canActivate: [authGuard],
    children: [
      {path: '', redirectTo: 'vxod', pathMatch: 'full'},
      {path: 'vxod', component: SignInComponent},
      {path: 'registratsiya', component: SignUpComponent},
      {path: 'vosstanovlenie-parolya', component: ForgotPasswordComponent},
      {path: 'sbros-parolya', component: ResetPasswordComponent},
    ]
  },
  {
    path: 'ru/akkaunt',
    loadComponent: () => import('@app/layouts/account/account.component'),
    canActivate: [dashboardGuard],
    children: [
      {path: '', redirectTo: 'polzovatel', pathMatch: 'full'},
      {path: 'polzovatel', component: UserPageComponent},
      {path: 'bezopasnost', component: ChangePasswordPageComponent},
      {path: 'istoriya-platezhey', component: BillingPageComponent},
      {path: 'vybor-plana', component: ChoosePlanComponent},
    ]
  },
  {
    path: 'ru/panel-upravleniya',
    loadComponent: () => import('@app/layouts/dashboard/dashboard.component'),
    canActivate: [dashboardGuard]
  },
  {
    path: 'ru/dokumenti/:documentId',
    loadComponent: () => import('@app/layouts/builder/builder.component'),
    canActivate: [dashboardGuard]
  },
  {path: 'ru/usloviya-ispolzovaniya', component: TermsOfUseComponent},
  {path: 'ru/politika-vozvratov', component: RefundPolicyComponent},
  {path: 'ru/confirm-email', component: ConfirmEmailComponent},
  {path: 'ru/politika-konfidencialnosti', component: PrivacyPolicyComponent},
  {path: 'ru/kontakty', component: ContactUsComponent},
  {path: 'ru/o-nas', component: AboutUsComponent},
  {
    path: 'ru/template-print/3cc7a492-8c98-4133-8b3d-a1bef3f1c9ce/:documentId',
    component: TmPrintPageComponent
  }
];

const fr_routes: Routes = [
  {
    path: 'fr',
    loadComponent: () => import('@app/layouts/main-layout/main-layout.component'),
    children: [
      {path: '', component: HomePageComponent},
      {path: 'modeles-de-cv', component: CvTemplatesPageComponent}, // 'cv-templates' => 'modeles-de-cv'
      {path: 'lettres-de-motivation', component: CoverLettersPageComponent}, // 'cover-letters' => 'lettres-de-motivation'
      {path: 'tarifs', component: PricingPageComponent}, // 'pricing' => 'tarifs'
    ],
  },
  {
    path: 'fr/authentification', // 'auth' => 'authentification'
    loadComponent: () => import('@app/layouts/auth/auth.component'),
    canActivate: [authGuard],
    children: [
      {path: '', redirectTo: 'connexion', pathMatch: 'full'}, // 'sign-in' => 'connexion'
      {path: 'connexion', component: SignInComponent}, // 'sign-in' => 'connexion'
      {path: 'inscription', component: SignUpComponent}, // 'sign-up' => 'inscription'
      {path: 'mot-de-passe-oublie', component: ForgotPasswordComponent}, // 'forgot-password' => 'mot-de-passe-oublie'
      {path: 'reinitialisation-mot-de-passe', component: ResetPasswordComponent}, // 'reset-password' => 'reinitialisation-mot-de-passe'
    ]
  },
  {
    path: 'fr/compte', // 'account' => 'compte'
    loadComponent: () => import('@app/layouts/account/account.component'),
    canActivate: [dashboardGuard],
    children: [
      {path: '', redirectTo: 'utilisateur', pathMatch: 'full'}, // 'user' => 'utilisateur'
      {path: 'utilisateur', component: UserPageComponent}, // 'user' => 'utilisateur'
      {path: 'securite', component: ChangePasswordPageComponent}, // 'security' => 'securite'
      {path: 'historique-paiement', component: BillingPageComponent}, // 'billing-history' => 'historique-paiement'
      {path: 'choisir-un-plan', component: ChoosePlanComponent}, // 'choose-plan' => 'choisir-abonnement'
    ]
  },
  {
    path: 'fr/tableau-de-bord', // 'dashboard' => 'tableau-de-bord'
    loadComponent: () => import('@app/layouts/dashboard/dashboard.component'),
    canActivate: [dashboardGuard],
  },
  {
    path: 'fr/documents/:documentId',
    loadComponent: () => import('@app/layouts/builder/builder.component'),
    canActivate: [dashboardGuard]
  },
  {path: 'fr/conditions-utilisation', component: TermsOfUseComponent}, // 'terms-of-use' => 'conditions-utilisation'
  {path: 'fr/politique-remboursement', component: RefundPolicyComponent}, // 'refund-policy' => 'politique-remboursement'
  {path: 'fr/confirmation-email', component: ConfirmEmailComponent}, // 'confirm-email' => 'confirmation-email'
  {path: 'fr/politique-confidentialite', component: PrivacyPolicyComponent}, // 'privacy-policy' => 'politique-confidentialite'
  {path: 'fr/nous-contacter', component: ContactUsComponent}, // 'contact-us' => 'nous-contacter'
  {path: 'fr/a-propos', component: AboutUsComponent}, // 'about-us' => 'a-propos'
  {
    path: 'fr/template-print/3cc7a492-8c98-4133-8b3d-a1bef3f1c9ce/:documentId', // 'template-print' => 'impression-modele'
    component: TmPrintPageComponent
  },
];

const de_routes: Routes = [
  {
    path: 'de',
    loadComponent: () => import('@app/layouts/main-layout/main-layout.component'),
    children: [
      {path: '', component: HomePageComponent},
      {path: 'cv-vorlagen', component: CvTemplatesPageComponent},
      {path: 'bewerbungsschreiben', component: CoverLettersPageComponent},
      {path: 'preise', component: PricingPageComponent},
    ],
  },
  {
    path: 'de/auth',
    loadComponent: () => import('@app/layouts/auth/auth.component'),
    canActivate: [authGuard],
    children: [
      {path: '', redirectTo: 'einloggen', pathMatch: 'full'},
      {path: 'einloggen', component: SignInComponent},
      {path: 'registrieren', component: SignUpComponent},
      {path: 'passwort-vergessen', component: ForgotPasswordComponent},
      {path: 'passwort-zuruecksetzen', component: ResetPasswordComponent},
    ]
  },
  {
    path: 'de/konto',
    loadComponent: () => import('@app/layouts/account/account.component'),
    canActivate: [dashboardGuard],
    children: [
      {path: '', redirectTo: 'benutzer', pathMatch: 'full'},
      {path: 'benutzer', component: UserPageComponent},
      {path: 'sicherheit', component: ChangePasswordPageComponent},
      {path: 'zahlungsverlauf', component: BillingPageComponent},
      {path: 'abo-waehlen', component: ChoosePlanComponent},
    ]
  },
  {
    path: 'de/dashboard',
    loadComponent: () => import('@app/layouts/dashboard/dashboard.component'),
    canActivate: [dashboardGuard],
  },
  {
    path: 'de/dokumente/:documentId',
    loadComponent: () => import('@app/layouts/builder/builder.component'),
    canActivate: [dashboardGuard]
  },
  {path: 'de/nutzungsbedingungen', component: TermsOfUseComponent},
  {path: 'de/rueckerstattungsrichtlinie', component: RefundPolicyComponent},
  {path: 'de/email-bestaetigung', component: ConfirmEmailComponent},
  {path: 'de/datenschutzrichtlinie', component: PrivacyPolicyComponent},
  {path: 'de/kontakt', component: ContactUsComponent},
  {path: 'de/ueber-uns', component: AboutUsComponent},
  {
    path: 'de/template-print/3cc7a492-8c98-4133-8b3d-a1bef3f1c9ce/:documentId',
    component: TmPrintPageComponent
  },
];

const es_routes: Routes = [
  {
    path: 'es',
    loadComponent: () => import('@app/layouts/main-layout/main-layout.component'),
    children: [
      {path: '', component: HomePageComponent},
      {path: 'plantillas-cv', component: CvTemplatesPageComponent},
      {path: 'cartas-de-presentacion', component: CoverLettersPageComponent},
      {path: 'precios', component: PricingPageComponent},
    ],
  },
  {
    path: 'es/autenticacion',
    loadComponent: () => import('@app/layouts/auth/auth.component'),
    canActivate: [authGuard],
    children: [
      {path: '', redirectTo: 'iniciar-sesion', pathMatch: 'full'},
      {path: 'iniciar-sesion', component: SignInComponent},
      {path: 'registrarse', component: SignUpComponent},
      {path: 'olvido-contraseña', component: ForgotPasswordComponent},
      {path: 'restablecer-contraseña', component: ResetPasswordComponent},
    ]
  },
  {
    path: 'es/cuenta',
    loadComponent: () => import('@app/layouts/account/account.component'),
    canActivate: [dashboardGuard],
    children: [
      {path: '', redirectTo: 'usuario', pathMatch: 'full'},
      {path: 'usuario', component: UserPageComponent},
      {path: 'seguridad', component: ChangePasswordPageComponent},
      {path: 'historial-facturacion', component: BillingPageComponent},
      {path: 'elegir-plan', component: ChoosePlanComponent},
    ]
  },
  {
    path: 'es/panel-de-control',
    loadComponent: () => import('@app/layouts/dashboard/dashboard.component'),
    canActivate: [dashboardGuard],
  },
  {
    path: 'es/documentos/:documentId',
    loadComponent: () => import('@app/layouts/builder/builder.component'),
    canActivate: [dashboardGuard]
  },
  {path: 'es/terminos-de-uso', component: TermsOfUseComponent},
  {path: 'es/politica-de-reembolso', component: RefundPolicyComponent},
  {path: 'es/confirmar-correo', component: ConfirmEmailComponent},
  {path: 'es/politica-de-privacidad', component: PrivacyPolicyComponent},
  {path: 'es/contacto', component: ContactUsComponent},
  {path: 'es/acerca-de-nosotros', component: AboutUsComponent},
  {
    path: 'es/template-print/3cc7a492-8c98-4133-8b3d-a1bef3f1c9ce/:documentId',
    component: TmPrintPageComponent
  },
];

export const routes: Routes = [
  ...en_routes,
  ...ru_routes,
  ...fr_routes,
  ...de_routes,
  ...es_routes,

  {path: '**', component: PageNotFoundComponent}
];

