import {HttpClient} from "@angular/common/http";
import {inject, Injectable} from '@angular/core';
import {setContext} from "@app/shared/constants";
import {Blog} from "@app/shared/interfaces/blog";

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private httpClient = inject(HttpClient);

  constructor() {
  }

  public getBlog() {
    return this.httpClient.get<Blog[]>(`assets/data/blog.json`, {
      context: setContext({skipPrefix: true, skipAuth: true, cache: true})
    });
  }

}
