import {RouterLink} from "@angular/router";
import {LngRoutePipe} from "@app/shared/pipes";
import {Component, inject} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {AppStateService} from "@app/shared/services/app-state.service";
import {FooterComponent} from "@app/shared/ui-kits/footer/footer.component";
import {HeaderComponent} from "@app/shared/ui-kits/header/header.component";

@Component({
  selector: 'sf-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  imports: [
    HeaderComponent,
    FooterComponent,
    NgOptimizedImage,
    RouterLink,
    TranslateModule,
    LngRoutePipe
  ]
})
export class PageNotFoundComponent {
  protected readonly appStateService = inject(AppStateService)
}
