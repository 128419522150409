<sf-templates-section
  ngTitle="Cover Letter"
  [h2Text]="'cover_letters_page.find' | translate"
  [pText]="'cover_letters_page.explore' | translate"
  [templates]="appStateService.coverLetters"/>
<sf-why-use-us-section/>
<sf-resources-section [ngTitle]="'Cover Letters'" [resources]="resources"/>
@defer (hydrate on idle) {
  <sf-resume-examples-section/>
}
@if (appStateService.FAQObj) {
  <sf-faq-section [faq]="appStateService.FAQObj.coverLetter"/>
}

<sf-more-then-section/>
